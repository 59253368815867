import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "hooks/AnimationRevealPage.js";
import AnimationRevealPageZero from "hooks/AnimationRevealPageZero.js";

import Footer from "bricks/Footer.js";

import Hero from "./hero.js";
import History from "./history.js";
import Contacts from "./contacts.js";

export default () => (
<>
  <AnimationRevealPageZero>
    <Hero />
  </AnimationRevealPageZero>
  <AnimationRevealPage>
  <History />
  </AnimationRevealPage>
  <AnimationRevealPage>
  <Contacts />
  </AnimationRevealPage>
  <AnimationRevealPageZero>
    <Footer />
  </AnimationRevealPageZero>
</>
);
