import React from 'react';
import TextTransition, { presets } from 'react-text-transition';

const TEXTS = ['без потерь', 'безопасно', 'без задержек', 'стабильно'];

const Carusell = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      1800, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

return (<TextTransition delay={40} inline={true} springConfig={presets.slow}>{TEXTS[index % TEXTS.length]}</TextTransition>
  );
};

export default Carusell;