import React from "react";
import AnimationRevealPage from "hooks/AnimationRevealPage.js";
import AnimationRevealPageZero from "hooks/AnimationRevealPageZero.js";
import Hero from "bricks/Hero.js";
import Feature1 from "bricks/Feature1.js";
import Feature2 from "bricks/Feature2.js";
import Feature3 from "bricks/Feature3.js";
import Feature4 from "bricks/Feature4.js";
import Feature5 from "bricks/Feature5.js";
import Blog from "bricks/Blog.js";
import Testimonial from "bricks/Testimonial.js";
import ContactUsForm from "bricks/ContactUsForm.js";
import Footer from "bricks/Footer.js";

import { Scrollbars } from 'react-custom-scrollbars';

export default () => (
 <>
 <Scrollbars autoHide autoHideTimeout={350} renderTrackVertical={props => <div style={{position: 'absolute', width: '6px', transition: 'opacity 200ms ease 0s', opacity: 0, right: '0.5%', 'min-right': '4rem', bottom: '2px', top: '2px', 'border-radius': '8rem'}}/>} style={{ widht: '100%', height: '100vh' }}>
  <div id="hello">
  <AnimationRevealPageZero>
    <Hero />
  </AnimationRevealPageZero>
  </div>
  <div id="features">
  <AnimationRevealPage>
    <Feature1 />
    <Feature2 />
    <Feature3 />
    <Feature4 />
    <Feature5 />
  </AnimationRevealPage>
  </div>
  <div id="end">
  <AnimationRevealPageZero>
    <Footer />
  </AnimationRevealPageZero>
  </div>
 </Scrollbars>
 </>
);
