import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../images/logo.png";
import { ReactComponent as TelegramIcon } from "../images/telegram-icon.svg";
import { ReactComponent as YoutubeIcon } from "../images/youtube-icon.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-center lg:justify-between text-center md:text-left md:space-y-0 space-y-10 md:space-x-0`;

const Column = tw.div`md:w-1/5 lg:text-left text-center items-center w-8/12`;
const WideColumn = tw(Column)`text-center items-center justify-center md:text-left w-full md:w-2/5 mb-10 md:mb-0 lg:text-left`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start md:mx-2`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const Yearold = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-4 `;

const SocialLinksContainer = tw.div`mt-4 lg:text-left `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mx-2`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Meonao</LogoText>
          </LogoContainer>
          <Yearold>
            18+
          </Yearold>
          <SocialLinksContainer>
            <SocialLink href="https://meonao.t.me">
              <TelegramIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com/@meonao">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>💳</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="https://meonao.com/npds2">Магазин</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="https://meonao.com/3d">Моделирование</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="https://meonao.com/freza">Механическая обработка</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>💫</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="https://meonao.com/mdpi">mDPI</Link>
            </LinkListItem>
			<LinkListItem>
              <Link href="https://meonao.com/mcensor">mCensor</Link>
            </LinkListItem>
			<LinkListItem>
              <Link href="https://meonao.com/mschlau">mSchlau</Link>
			</LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>😎</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="contacts">Контакты</Link>
            </LinkListItem>
			<LinkListItem>
              <Link href="mailto:hello@meonao.com">Email</Link>
            </LinkListItem>
			<LinkListItem>
              <Link href="https://t.me/+79952325000">Telegram</Link>
            </LinkListItem>
			<LinkListItem>
              <Link href="https://yandex.ru/profile/237159166874">Яндекс.Карты</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
