import React from "react";
import AnimationRevealPage from "hooks/AnimationRevealPage.js";
import AnimationRevealPageZero from "hooks/AnimationRevealPageZero.js";
import Hero from "./hero.js";
import Feature1 from "./Feature1.js";
import Feature2 from "./Feature2.js";
import Blog from "bricks/Blog.js";
import Testimonial from "bricks/Testimonial.js";
import ContactUsForm from "bricks/ContactUsForm.js";
import Footer from "bricks/Footer.js";

import { Scrollbars } from 'react-custom-scrollbars';

export default () => (
 <>
 <Scrollbars autoHide autoHideTimeout={350} renderTrackVertical={props => <div style={{position: 'absolute', width: '6px', transition: 'opacity 200ms ease 0s', opacity: 0, right: '0.5%', 'min-right': '4rem', bottom: '2px', top: '2px', 'border-radius': '8rem'}}/>} style={{ widht: '100%', height: '100vh' }}>
  <div id="hello">
  <AnimationRevealPageZero>
    <Hero />
  </AnimationRevealPageZero>
  <AnimationRevealPageZero>
    <Feature1 />
  </AnimationRevealPageZero>
  </div>
  <div id="end">
  <AnimationRevealPageZero>
    <Footer />
  </AnimationRevealPageZero>
  </div>
 </Scrollbars>
 </>
);
