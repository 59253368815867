import React from "react";
import {Helmet} from 'react-helmet';
import AnimationRevealPage from "hooks/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "./header.js";
import Footer from "bricks/Footer.js";
import MainFeature1 from "pages/net/feature1.js";
import MainFeature2 from "pages/net/feature2.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "pages/net/features.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import AnimationRevealPageZero from "hooks/AnimationRevealPageZero.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import ServerIconImage from "images/server.png";
import ConnectionIconImage from "images/connection.png";
import SecureIconImage from "images/secure.png";
import StabIconImage from "images/stab.png";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
	<>
	<Helmet>
		<style>{'body { background-color: fffff0; }'}</style>
	</Helmet>	
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Связность</Subheading>}
        heading="Приватная сеть"
        imageSrc="https://s.meonao.com/technology/ip.png"
        textOnLeft={false}
      />
      <MainFeature2
        subheading={<Subheading>Удалённый доступ</Subheading>}
        heading="Порт-форвардинг"
        imageSrc="https://s.meonao.com/net/port.png"
        textOnLeft={true}
      />
      <Features
        subheading={<Subheading></Subheading>}
        heading="Что мы предлагаем?"
        description=""
        cards={[
          {
            imageSrc: ServerIconImage,
            title: "Надёжность",
            description: "Лучшие маршруты здесь и сейчас по OSPF"
          },
          {
            imageSrc: ConnectionIconImage,
            title: "Скоростная сеть",
            description: "10G порты маршрутизаторов"
          },
          {
            imageSrc: SecureIconImage,
            title: "Защищённость",
            description: "Протоколы с быстрым и устойчивым шифрованием"
          },
          {
            imageSrc: StabIconImage,
            title: "Стабильность",
            description: "UDP-транспорт и особые алгоритмы управления каналом"
          },
        ]}
        linkText=""
      />
	</AnimationRevealPage>
	<AnimationRevealPageZero>
      <Footer />
	</AnimationRevealPageZero>
	</>
  );
};
